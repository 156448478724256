export const pagesQuery = `
query Pages {
  pages(first: 100) {
    nodes {
      id
      slug
      content
      title
      status
      featuredImage {
        node {
          link
          altText
        }
      }
    }
  }
}
`;