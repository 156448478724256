<template>
  <div>
    <!-- <modal-window
      @closeModal="closeModalWindow"
      :showModal="modalWindow"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></modal-window>
    <ModalPlace
      @closeModal="closeModalWindowPlace"
      :showModal="modalPlace"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></ModalPlace> -->
    
    <CalculatorNew />
    <service-list></service-list>
    <car-list></car-list>
    <block-info></block-info>
    <frequently-asked-questions></frequently-asked-questions>
    <ReviewsBlock />
    <subscribe-block></subscribe-block>
    <our-contacts></our-contacts>

    <!-- <modal-window
      @closeModal="closeModalWindow"
      :showModal="modalWindow"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></modal-window>
    <ModalPlace
      @closeModal="closeModalWindowPlace"
      :showModal="modalPlace"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></ModalPlace> -->
    <!-- <succes-block
      :showSucces="succesBlockVal"
      @closeSucces="closeSuccesBlock"
      @services="closeSuccesBlock"
    >
    </succes-block> -->
  </div>
</template>

<script>
// import SubHeader from "@/components/SubHeader.vue";
import CalculatorNew from '../components/CalculatorNew.vue'
import ReviewsBlock from '../components/ReviewsBlock.vue'
import ServiceList from '../components/ServiceList.vue'
// import CustomSelect from "@/components/UI/CustomSelect.vue";
// import CustomInput from "../components/UI/CustomInput.vue";
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
// import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  // computed,
  // watch,
  // onBeforeMount,
} from "vue";
// import { useRouter, useRoute } from "vue-router";
import BlockInfo from "../components/BlockInfo.vue";
import CarList from "../components/CarList.vue";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.vue";
import SubscribeBlock from "../components/SubscribeBlock.vue";
import OurContacts from "../components/OurContacts.vue";
// import { useI18n } from "vue-i18n";
// import CarSelect from "@/components/UI/CarSelect.vue";
// import CitySelect from "@/components/UI/CitySelect.vue";
// import city_info from "../../cityRange.json";
// import ModalWindow from "../components/ModalWindow.vue";
// import ModalPlace from "../components/ModalPlace.vue";
// import { toast } from "vue3-toastify";
// import "vue3-toastify/dist/index.css";
// import SuccesBlock from "../components/SuccesBlock.vue";
// import numeral from 'numeral';
export default {
  name: "HomeView",
  components: {
    CalculatorNew,
    ServiceList,
    ReviewsBlock,
    // CustomSelect,
    // CustomInput,
    // VueDatePicker,
    // VueTimepicker,
    BlockInfo,
    CarList,
    FrequentlyAskedQuestions,
    SubscribeBlock,
    OurContacts,
    // CarSelect,
    // CitySelect,
    // ModalWindow,
    // SuccesBlock,
    // ModalPlace,
  },

  setup() {
    // const router = useRouter();
    // const route = useRoute();

    const images = {
      default: require("../assets/Photo.jpg"),
      alternative: require("../assets/Rectangle 2729.jpg"),
    };
    const currentImage = ref(images.default);
    const handleResize = () => {
      if (window.innerWidth < 770) {
        currentImage.value = images.alternative;
      } else {
        currentImage.value = images.default;
      }
    };


    onMounted(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      // loader,
      // succesBlock,
      // closeSuccesBlock,
      // route,
      // router,
      // category,
    };
  },
};
</script>
<style lang="scss">
.check-box {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  padding-top: 10px;

  cursor: pointer;
  span {
    color: #23262f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
}
.hr {
  width: 100%;
  background-color: #f2f4f5;
  height: 1px;
  border: none;
  background: #f2f4f5;
}
.worker {
  display: flex;
  align-items: baseline;
  padding-left: 5px;
  gap: 25px;
  .worker-loader {
    display: flex;
    align-items: baseline;
  }
  .worker-loaders-time {
    display: flex;
    align-items: baseline;
  }
  .loaders-time {
    display: flex;
    align-items: baseline;
  }
}
.after-worker {
  display: flex;
  align-items: baseline;
}
.loader-time {
  display: flex;
  align-items: baseline;
}
.bool-worker {
  display: flex;
  gap: 23px;
}
.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:30px;
  h2 {
    color: #23262f;
    font-family: "Manrope", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 20px;
  }
}
.huflDf {
  display: none !important;
}
.countLoad {
  color: #23262f;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.valid-input {
  color: red;
  border-bottom: 2px dashed red;
}
.dp__pointer {
  border: none !important;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.dp__icon {
  display: none !important;
}
.dp__input_icon_pad {
  padding: 0;
}
.dp__input {
  padding: 4px 0px 0 7px;
}
.dp__input_wrap {
  width: 100px !important;
}

.bool-time-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .bool-time {
    display: flex;
    align-items: center;
    gap: 25px;
    .bool-calendar {
      position: relative;
      display: flex;
    }
    .bool-calendar::after {
      content: "";
      display: block;
      width: 1px;
      background-color: #979c9e;
      height: 100%;
      position: absolute;
      top: 0;
      right: -10%;
      transform: translateX(50%);
    }
    .bool-clock {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
}
.cars {
  display: flex;
  .select-car {
    padding-left: 6px;
  }
  .select-time-car {
    display: flex;
    align-items: baseline;
  }
}

.wrapper-inputs {
  padding: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1440px;
  margin: 0 auto;
}
.container-small {
  padding: 0 15px;
  max-width: 1220px;
  margin: 0 auto;
}
.subsc-hr {
  width: 100%;
  background-color: #f2f4f5;
  height: 1px;
  border: none;
  background: #f2f4f5;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 0;
}
.adaptiv-hr {
  height: 1px;
  border: none;
  background: #f2f4f5;
  z-index: 1;
  position: absolute;
  left: 0px;
  width: 100%;
}
.fontSize {
  font-size: 14px;
}

.swiper-slide-card {
  max-width: 280px;
}

.vue__time-picker input.vue__time-picker-input {
  border: none;
  width: 4em;
  height: none;
  padding: 0;
  font-size: 1em;
}

.vue__time-picker {
  width: 3em;
}

.vue__time-picker input.vue__time-picker-input {
  color: #23262f;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 3em;
}
.vue__time-picker .dropdown {
  left: -60px;
}

.choice {
  background: #23262f !important;
  border: 2px solid #23262f !important;
  span {
    color: #fff !important;
  }
}
.home {
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;

  &__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .info-img {
      background-image: url("../assets/Photo.jpg");
      background-size: cover;
      background-position: center;
      border-radius: 24px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      .background-img {
        border-radius: 40px;
      }
      .partner {
        // position: absolute;
        top: 104px;
        max-width: 533px;
        left: 2%;
        .map {
          padding-top: 20px;
        }
        .partner-text {
          color: #fff;
          font-family: "Manrope", sans-serif;
          font-size: 55px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }

      .partner-adaptiv {
        // position: absolute;
        top: 56px;
        left: 31px;
        max-width: 222px;
        span {
          color: #fff;
          font-family: "Manrope", sans-serif;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 40.5px;
        }
      }
      .application {
        display: flex;
        padding: 30px;
        gap: 20px;
        border-radius: 24px;
        border: 1px solid var(--neutrals-8, #fcfcfd);
        background: #fff;
        box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
        flex-direction: column;
        hr {
          width: 100%;
          background-color: #f2f4f5;
          height: 1px;
          border: none;
          background: #f2f4f5;
        }
        .date {
          display: flex;
          flex-direction: column;
          padding-left: 6px;
          gap: 5px;
          .date-block {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .date-block-logisticks {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .geolocation-inputs-time {
            display: flex;
            align-items: center;
            // justify-content: space-around;
            .times {
              max-width: 75px;
            }
          }
          hr {
            width: 1px;
            height: 24px;
            background: #979c9e;
          }
          .time-hr {
            width: 1px;
            height: 24px;
            background: #979c9e;
          }
          .time {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            .calendar {
              position: relative;
              display: flex;
            }
            .calendar::after {
              content: "";
              display: block;
              width: 1px;
              background-color: #979c9e;
              height: 100%;
              position: absolute;
              top: 0;
              right: -4%;
              /* left: 0px; */
              transform: translateX(50%);
            }
            .clock {
              display: flex;
              position: relative;
            }
          }
        }
        .navigation-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .relocation {
            cursor: pointer;
            width: 203px;
            height: 40px;

            border-radius: 90px;
            gap: 12px;
            border-radius: 90px;
            background: white;
            border: 2px solid #e6e8ec;
            .relocation-text {
              color: #23262f;
              text-align: center;
              font-family: "Manrope", sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }

          .logistics {
            cursor: pointer;
            width: 203px;
            height: 40px;
            // padding: 12px 16px 12px 16px;
            border-radius: 90px;
            gap: 12px;
            border-radius: 90px;
            background: white;
            border: 2px solid #e6e8ec;
            .logistics-text {
              color: #23262f;
              text-align: center;
              font-family: "Manrope", sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }
        }

        .geolocation-inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .geolocation-inputs-cars {
        }
        .cost-of-services {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          gap: 12px;
          padding-bottom: 10px;
          .cost {
            color: #979c9e;
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          .sum {
            color: #23262f;
            text-align: center;
            font-family: "Manrope", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
          }
        }

        .reg-application {
          display: flex;
          justify-content: center;
          button {
            cursor: pointer;
            display: flex;
            width: 336px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 90px;
            border: 1px solid #f58a1f;
            background: #f58a1f;
            span {
              color: #fff;
              text-align: center;
              font-family: "Manrope", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
            }
          }
        }
      }
    }
  }
  &__card {
    .services {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 50px;
      h2 {
        color: #23262f;
        font-family: "Manrope", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        margin-left: 7px;
      }
      .services-btn {
        display: flex;
        padding-right: 0;
        gap: 10px;

        button {
          border: 1px solid transparent;
        }

        .right {
          cursor: pointer;
          display: flex;
          padding: 3px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 40px;
          background: white;
        }
        .right:hover {
          border: 1px solid #999b9c;
        }
        .left {
          cursor: pointer;
          display: flex;
          padding: 3px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 40px;
          background: white;
        }
        .left:hover {
          border: 1px solid #999b9c;
        }
      }
    }
    .cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .img {
      }
      .text {
        padding-top: 10px;
        span {
          color: #23262f;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }
      .text-info {
        span {
          color: #72777a;
          font-family: "Manrope", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .home__card {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1220px) {
  .home__info .info-img .application .navigation-buttons {
    flex-direction: column;
    gap: 10px;
  }
  .home__info .info-img .application .date {
    flex-direction: column;
    gap: 6px;
  }
  .home__info .info-img .application .geolocation-inputs-cars {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    padding-left: 6px;
    gap: 10px;
  }
  .home__info .info-img .application {
    gap: 5px;
    padding: 27px;
  }
  .wrapper-inputs {
    padding: 25px 0px 0px 0px;
  }

  .home__info .info-img .application .date .geolocation-inputs-time {
    display: flex;
    align-items: center;
    margin-left: 13px;
    justify-content: space-around;
  }
  .home__info .info-img .application .date .time-hr {
    display: none;
  }
  .home__info .info-img .application .date hr {
    display: none;
  }
  .home__info .info-img .application .date {
    padding-left: 12px;
  }
}

@media (max-width: 1190px) {
  .home__card {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1100px) {
  .home__info .info-img .partner .map {
    display: none;
  }
  .home__info .info-img {
    flex-direction: column;
    padding: 5px 0px 20px 0px;
    gap: 20px;
  }
}

@media (min-width: 770px) {
  .partner-adaptiv {
    display: none;
  }
  .adaptiv-hr {
    display: none;
  }
  .bool-worker .after-timeWorker::after {
    content: "";
    display: block;
    width: 1px;
    background-color: #979c9e;
    height: 68%;
    position: absolute;
    top: 6px;
    /* right: 6%; */
    left: -9px;
    transform: translateX(50%);
  }
}

@media (max-width: 770px) {
  .worker{
    gap:0;
    flex-direction: column;
  }
  .home__info {
    margin: 0 -15px;
  }
  .bool-worker {
    flex-direction: column;
    gap: 5px;
  }
  .subsc-hr {
    display: none;
  }
  .bool-time-block {
    padding-left: 6px;
  }
  .cars {
    flex-direction: column;
  }

  .home__info .info-img .application .cost-of-services {
    gap: 5px;
  }
  .home__info .info-img .application .geolocation-inputs-cars {
    padding-left: 3px;
  }
  .home__info .info-img .partner {
    padding-top: 30px;
  }
  .home__info .info-img .partner-adaptiv span {
    font-size: 36px;
    line-height: 51.5px;
  }

  .home__info .info-img .partner .partner-text {
    display: none;
  }
  .home__info .info-img .background-img {
    max-width: 755px;
    min-height: 576px;
  }

  .home__info .info-img .partner-adaptiv {
    max-width: 312px;
  }

  .sub-header__order-btn {
    display: none;
  }
  .sub-header__logo .lang {
    display: none;
  }
  .home__info .info-img .application .date .date-block {
    align-items: stretch;
    flex-direction: column;
    padding-left: 6px;
  }
  .home__info .info-img .application .date .geolocation-inputs-time {
    padding-right: 30px;
    margin-left: 0;
  }
  .home__info .info-img .application .navigation-buttons {
    flex-direction: row;
  }

  .home__info .info-img .application .date {
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
  }
  .home__info .info-img .application .date .geolocation-inputs-time {
    flex-direction: row;
  }

  .home__card .services .services-btn {
    margin-right: 0;
    padding-right: 0;
  }

  .sub-header__logo img {
    max-width: 124px;
  }
  .sub-header__logo {
    left: 2%;
  }
  .home__info .info-img .background-img {
    min-width: 386px;
  }
  .home__info .info-img .application .geolocation-inputs {
    padding: 0;
  }

  .home__info .info-img .application {
    top: 22%;
    right: 21px;
    min-width: 0;
    padding: 40px;
    gap: 20px;
    max-width: 266px;
    left: unset;
  }
}
@media (max-width: 550px) {
  .home__info .info-img .application .navigation-buttons {
    flex-direction: column;

    button {
      width: 100% !important;
    }
  }
}

@media (max-width: 480px) {
  .home__info .info-img {
    background-image: url("../assets/Rectangle 2729.jpg");
    background-size: cover;
    background-position: center;
  }
  .home__info .info-img .partner {
    //padding-top: 50px;
  }
  .wrapper-inputs{
    gap: 15px;
  }
  .home__info .info-img .application .date .date-block-logisticks{
    padding-left: 8px;
  }
  .home__info .info-img .partner-adaptiv span {
    font-size: 37px;
    line-height: 41.5px;
  }
}
@media (max-width: 400px) {
  .home__card .cards .text span {
    font-size: 20px;
  }
  .home__card .services .services-btn {
    margin-right: 0;
  }

  .sub-header__logo img {
    max-width: 124px;
  }

  .home__info .info-img .background-img {
    min-width: 386px;
  }
  .vuejs3-datepicker__value {
    margin-left: 3px;
    min-width: 86px !important;
  }

  .home__info .info-img .application .geolocation-inputs {
    padding: 0;
  }

  .home__info .info-img .application {
    padding: 40px 20px;
  }
  .home__info .info-img .partner-adaptiv{
    padding-left: 40px;
  }
}
@media(max-width:355px){
  .home__info .info-img .partner-adaptiv{
    padding-left: 30px;
    padding-right: 15px;
  }
}
</style>
