export const carsQuery = `
query CarsItems {
  allCar(first: 100) {
    nodes {
      id
      slug
      status
      title
      featuredImage {
        node {
          link
          altText
        }
      }
      excerpt
      content
    }
  }
}
`;
